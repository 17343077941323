.wrap {
  min-height: 100vh;
  padding: 42px 0;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-between;
}

.logo {
  height: 70px;
  text-align: center;
  margin-bottom: 15px;

  img {
    height: 100%;
  }
}

.container {
  background-color: #FFFFFF;
  padding: 40px 83px;
  width: 561px;
}

.container1 {
  background-color: #FFFFFF;
  padding: 40px 83px;
  width: 661px;
}
