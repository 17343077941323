$primary-color-2: #000000;
$primary-color: #0275d8;

$primary: #000000;
$body-color: #{$primary};
$body-bg: #f4f7fb;
$input-btn-padding-x: 1rem;
$input-btn-padding-y: 0.875rem;

$form-check-input-margin-y: 0.25rem;
$form-check-input-gutter: 1.5rem;

// css variables
:root {
    --primary-color-2: #{$body-color};
    --primary-bg-color: #f4f7fb;
    --header-color: #304676;
    --highlight-bg: #e3ebfd;
}

@import "~bootstrap/scss/bootstrap.scss";
@import "~react-perfect-scrollbar/dist/css/styles.css";
@import "~react-notifications-component/dist/theme.css";
@import "~react-datepicker/dist/react-datepicker.css";

.cursor-pointer {
    cursor: pointer;

    &:disabled {
        cursor: not-allowed;
    }
}

.font-weight-500 {
    font-weight: 500 !important;
}

// bootstrap overrides //
$font-size-md: $font-size-base * 1.125 !default;
$input-btn-font-size-md: $font-size-md !default;
$btn-font-size-md: $input-btn-font-size-md !default;

$line-height-md: 1.278 !default;
$input-btn-line-height-md: $line-height-md !default;
$btn-line-height-md: $input-btn-line-height-md !default;

$border-radius-md: 0.25rem !default;
$btn-border-radius-md: $border-radius-md !default;

$input-btn-padding-y-md: 0.594rem !default;
$btn-padding-y-md: $input-btn-padding-y-md !default;

$input-btn-padding-x-md: 0.75rem !default;
$btn-padding-x-md: $input-btn-padding-x-md !default;

$input-line-height-md: 1.286 !default;
$input-padding-y-md: 0.75rem !default;
$input-height-md: add(
    $input-line-height-md * 1em,
    add($input-padding-y-md * 2, $input-height-border, false)
) !default;

$input-padding-x-md: $input-btn-padding-x-md !default;

$input-font-size-md: 0.875rem !default;

$input-border-radius-md: $border-radius-md !default;

.custom-select {
    padding-left: 0.8rem;
}

.btn-md {
    @include button-size(
        $btn-padding-y-md,
        $btn-padding-x-md,
        $btn-font-size-md,
        $btn-line-height-md,
        $btn-border-radius-md
    );
}

.form-control-md {
    height: $input-height-md;
    padding: $input-padding-y-md $input-padding-x-md;
    @include font-size($input-font-size-md);
    line-height: $input-line-height-md;
    @include border-radius($input-border-radius-md);
}

.modal.themed {
    button.close {
        position: absolute;
        right: 20px;
        top: 20px;
        font-weight: normal;
        padding: 0 4px;
    }
}
// end bootstrap overrides //

/** -- Scrollbar -- */
/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #a8a8a8;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #575757;
}
/** END of Scrollbar */

// global styling
body,
#root {
    height: 100vh;
    font-family: Poppins, Arial, sans-serif;
    scrollbar-width: thin;
    width: 100%;
}

.font-size-md {
    font-size: $font-size-md;
}

.smaller {
    font-size: 0.75rem;
}

.bitsmall {
    font-size: 0.875rem;
}

a:hover {
    text-decoration: none !important;
}

.modal-header {
    flex: 1 1 auto;
    display: block;
}
.modal-header .close {
    position: absolute;
    top: 15px;
    right: 15px;
}
.btn-sm,
.btn-group-sm > .btn {
    padding: 0.15rem 0.4rem;
}

.activeTabsPL {
    position: relative;
    padding: 0px 5px;
}
.activeTabsPL::after {
    background: rgba(5, 46, 80, 1);
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    left: 0px;
    bottom: -17px;
}

.vacanciesGeneral p {
    line-height: 27px;
}
.contentWrapper {
    width: calc(100% - 335px);
}

#vacancyAction {
    background: transparent;
    color: rgba(48, 70, 118, 1);
    padding: 0px;
}
.vacancyActionP .dropdown {
    text-align: right;
}
button#vacancyAction:hover,
button#vacancyAction:active {
    color: rgba(48, 70, 118, 1) !important;
}

.appGeneral {
    overflow: hidden;
}

.applicantDoc::after {
    background: #e6e9f3;
    content: "";
    position: absolute;
    height: 1px;
    width: 107%;
    left: -3%;
    top: 15px;
}

.nonLink {
    padding: 0px;
    background-color: transparent;
    color: #052e50;
    border: none;
    font-weight: 500;
}
.nonLink:hover,
.nonLink:active,
.nonLink:focus {
    background: transparent !important;
    color: #052e50 !important;
}
.vacanciesGenerala {
    overflow: hidden;
}
.applicantStageList {
    position: relative;
    padding-top: 40px;
    padding-bottom: 25px;
}
.applicantStageList:first-child {
    padding-top: 0px !important;
}
.applicantStageList:first-child::after {
    display: none;
}
.applicantStageList::after {
    background: #e6e9f3;
    content: "";
    position: absolute;
    height: 1px;
    width: 111%;
    left: -6%;
    top: 15px;
}

.WorkerList {
    width: 250px;
    margin: auto;
    background: #f4f7fb;
    border-left-width: 4px;
    border-left-style: solid;
    text-align: left;
    padding-left: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
    display: flex;
    border-radius: 5px;
    padding-right: 15px;
    position: relative;
    align-items: center;
}
.WorkerList span {
    font-size: 0.85em;
    list-style: 15px;
}
.WorkerList p {
    line-height: 18px;
}
.WorkerList img {
    width: 40px;
    height: 40px;
    margin-right: 15px;
    border-radius: 40px;
}

.editWorkers {
    border: none;
    position: absolute;
    background: transparent;
    right: 0px;
    top: 0px;
}

.sideBarNavigation {
    padding-top: 30px;
}
.sideBarNavigation .MuiPaper-root {
    box-shadow: none;
}
.sideBarNavigation .MuiAccordionSummary-root {
    border-top: 1px solid #e6e9f3;
    padding: 5px 30px;
}
.sideBarNavigation .MuiAccordionSummary-root p {
    color: rgba(5, 46, 80, 0.6);
    font-size: 1.1em;
    font-weight: 500;
    font-family: Poppins, Arial, sans-serif;
}
.sideBarNavigation svg {
    fill: #052e50;
}
.qr-code {
    max-width: 135px;
}
.MuiAccordionSummary-content {
    margin: 12px 0 !important;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded {
    margin-top: 0px !important;
}

.printButton {
    display: block;
    width: 100%;
    background: transparent;
    border: none;
    color: #052e50;
    font-weight: 500;
    margin-top: 12px;
}

.companyDetails {
    padding: 0px 15px;
}
.companyDetails span {
    color: rgba(0, 0, 0, 0.5);
    font-size: 0.8em;
}
.companyDetails p {
    color: #052e50;
}

.leftNo {
    padding-left: 0px !important;
}

.navi a:hover,
.navi a:active {
    background: rgba(227, 235, 253, 0.1) !important;
    color: #fff !important;
}
.btn-outline-light:hover,
.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
    background: rgba(227, 235, 253, 0.1) !important;
    color: #fff !important;
}
.sideBarNavigationAdmin .MuiAccordionSummary-root {
    border-top: 1px solid #e6e9f3;
    padding: 5px 50px;
}

.sideBarNavigationAdmin .companyDetails {
    padding: 0px 30px;
}

.plo {
    margin-left: 15px;
}
.header-mobile {
    display: none;
}
.headerMobileLogo {
    display: none;
}
@media (max-width: 992px) {
    .pr-5,
    .px-5 {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }

    .sideBar,
    .header-desktop {
        display: none !important;
    }

    .header-mobile {
        position: relative;
        display: block;
    }
    .headerMobileLogo {
        text-align: center;
        display: block;
        padding: 15px 0px;
    }
    .headerMobileLogo img {
        max-width: 120px;
    }

    .headerMobileRight {
        position: absolute;
        right: 15px;
        top: 10px;
    }
    .headerMobileLeft {
        position: absolute;
        left: 7px;
        top: 10px;
    }

    .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
        min-height: 30px !important;
    }

    .linksp {
        font-size: 1.1em;
        font-weight: 500;
    }

    .AuthLayout_container__1F6vX {
        width: 100%;
    }
}

.ant-menu {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-variant: tabular-nums;
    line-height: 1.5715;
    font-feature-settings: "tnum", "tnum";
    margin-bottom: 0;
    padding-left: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 0;
    text-align: left;
    list-style: none;
    outline: none;
    transition: background 0.3s, width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
}
.ant-menu-horizontal {
    line-height: 46px;
    border: 0;
    border-bottom: 0px solid #f0f0f0;
    box-shadow: none;
}
.ant-menu-overflow {
    display: flex;
}

.c-white {
    color: #fff;
}

.safetyList_:nth-child(odd) > div {
    padding-right: 25px;
}
.safetyList_:nth-child(even) > div {
    padding-left: 25px;
}

.actionButton {
    display: flex;
    justify-content: space-between;
    margin-right: 5px;
}
.actionButton button:first-child {
    margin-left: 0px !important;
}
.actionButton button:last-child {
    margin-right: 0px !important;
}
.actionButton button {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
}

.templates_style .action_style {
    width: 90px;
}
.archived_style .action_style {
    width: 90px;
}

.react-tel-input {
    position: relative;
}
.special-label {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding: 1rem 0.75rem;
    pointer-events: none;
    border: 1px solid transparent;
    transform-origin: 0 0;
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.react-tel-input input {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem;
    height: calc(3.5rem + 2px);
    line-height: 1.25;
}

.selectBoxWithoutLabel .form-group {
    margin-bottom: 0px;
}
.selectBoxWithoutLabel h6 {
    margin-bottom: 0px;
}
.selectBoxWithoutLabel .custom-select {
    padding: 10px 50px 10px 20px;
    height: auto;
}

.sidebarSub .MuiTypography-root {
    color: rgba(5, 46, 80, 0.6);
    text-decoration: none;
    background-color: transparent;
    font-size: 1.125rem;
    font-weight: 500 !important;
    font-family: Poppins, Arial, sans-serif;
    padding-left: 35px;
}

.sidebarSub .MuiAccordionSummary-content {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
}
.sidebarSub .MuiPaper-root {
    box-shadow: none;
}
.sidebarSub {
    padding: 10px 0px;
}
.sidebarSubUlLi li {
    list-style: none;
    padding: 5px 0px;
}
.sidebarSubUlLi li a {
    font-size: 0.95rem;
}

.DashboardLayout_menuWrap__Btncn a {
    color: rgba(5, 46, 80, 0.6);
}
.DashboardLayout_active__1Sfyk {
    background: transparent !important;
    color: rgba(5, 46, 80, 1) !important;
}

.SpecialOpen .Mui-expanded p {
    color: #052e50;
}

.chartP {
    font-size: 13px;
    text-align: left;
    margin-top: 7px;
    word-break: break-all;
    white-space: normal;
    width: 128px;
    line-height: 15px;
    font-weight: 500;
    margin-bottom: 0px;
}
.chartPSub {
    font-size: 13px;
    text-align: left;
}

.userTable td {
    word-wrap: break-word;
}

.bg-yellow {
    background-color: yellow;
}
